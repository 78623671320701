import axios from "axios";
import React, { useState, useEffect } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { addproduct } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import image from "../images/Website.png";

const WishList = () => {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const fetchWishList = async () => {
    if (!token) {
      setError("You need to log in to view the wishlist.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}wishlist/user_id_${userId}`
      );
      setWishlistItems(response.data.wishlistItems);
    } catch (error) {
      console.error("Error fetching wishlist items", error);
      setError("Failed to fetch wishlist items. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveItem = async (itemId, vendor_id, product_id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}wishlist/user_id_${userId}/vendor_id_${vendor_id}/product_id_${product_id}/wishlist_id_${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWishlistItems((prevItems) =>
        prevItems.filter((item) => item.id !== itemId)
      );
    } catch (error) {
      console.error("Error removing item from wishlist", error);
    }
  };

  const handleClearWishlist = () => {
    setWishlistItems([]);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    dispatch(addproduct());
    fetchWishList();
  }, [dispatch]);

  const handleLogin = () => {
    navigate("/login-user");
  };

  return (
    <div className="max-w-4xl mx-auto p-2">
      <div className="flex justify-center">
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <AiOutlineHeart className="mr-3 text-2xl" />
          My Wishlist
        </h2>
      </div>

      {error ? (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6 text-center mt-10 mb-10">
          <div className="flex justify-center mb-4">
            <img
              src={image}
              alt="Wishlist Icon"
              className="h-28 w-38"
            />
          </div>
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            Missing Wishlist items?
          </h2>
          <p className="text-gray-600 text-sm mb-4">
            Login to see the items you added previously to your Wishlist.
          </p>
          <button
            onClick={() => handleLogin()}
            className="bg-orange-500 text-white px-6 py-2 rounded-full hover:bg-orange-600 transition"
          >
            Login
          </button>
        </div>
      ) : loading ? (
        // Skeleton Loader
        <ul className="space-y-6">
          {Array(5)
            .fill()
            .map((_, index) => (
              <li
                key={index}
                className="flex justify-between items-center p-6 bg-white shadow-lg rounded-lg"
              >
                <div className="flex items-center">
                  <div className="relative w-20 h-20 mr-6">
                    <Skeleton height="100%" width="100%" />
                  </div>
                  <div>
                    <Skeleton height={20} width={150} />
                    <Skeleton height={15} width={100} style={{ marginTop: 10 }} />
                    <Skeleton height={15} width={80} style={{ marginTop: 10 }} />
                  </div>
                </div>
                <Skeleton height={25} width={50} />
              </li>
            ))}
        </ul>
      ) : (
        <>
          {wishlistItems.length > 0 ? (
            <ul className="space-y-6">
              {wishlistItems.map((item) => {
                const product = products.find((p) => p.id === item.product_id);

                return (
                  <li
                    key={item.id}
                    className="flex justify-between items-center p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
                  >
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => handleItemSelect(item)}
                    >
                      <div className="relative w-20 h-20 mr-6">
                        <img
                          src={
                            product
                              ? `https://ourmicrolife.com/ourmicrolife/storage/app/public/${product.featured_image}`
                              : "/placeholder.jpg"
                          }
                          alt={product ? product.name : "Product image"}
                          className="rounded-lg object-cover w-full h-full border"
                        />
                      </div>
                      <div>
                        <p className="text-lg font-bold">
                          {product ? product.name : "Product not available"}
                        </p>
                        <p>{item.color}</p>
                        <p>₹{item.sale_price}</p>
                      </div>
                    </div>
                    <button
                      className="flex items-center text-red-600 space-x-2 hover:text-red-800 transition duration-200"
                      onClick={() =>
                        handleRemoveItem(item.id, item.vendor_id, item.product_id)
                      }
                    >
                      <RiDeleteBin6Line className="text-xl" />
                      <span className="text-lg hidden sm:inline">Remove</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-center text-lg">Your wishlist is empty.</p>
          )}
        </>
      )}
    </div>
  );
};

export default WishList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  addproduct,
  // getCartItems,
  setUserProfile,
  userAddresses,
} from "./../../redux/action";
import { useNavigate, useParams } from "react-router-dom";

const BuyNowOrderSummary = () => {
  // Static cart items

  const [product, setProduct] = useState({});
  const [productName, setProductName] = useState("");
  const [Imagefeature, setImage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [addresses, setaddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(-1);
  const [payementType, setPayementType] = useState("cod");
  const [productPrice, setProductPrice] = useState([]);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [buyImage, setbuyImage] = useState([]);
  const [productid, setProductId] = useState(0);
  const [VendorId, setVendorId] = useState(0);
  const [foundProduct, setFoundProduct] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [price, setPrice] = useState([]);
  const [old_price,setOldPrice]=useState([])
  const [activeSection, setActiveSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successOrder, setSuccessOrder] = useState(false);

  const { id } = useParams();
  console.log("buyid", id);
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  const userProfile = useSelector((state) => state.userProfile);

  // const address = useSelector((state) => state.userAddresses);
  // console.log("The redux address is: ", address);

  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);
  console.log("product redux", product);
  useEffect(() => {
    const userAddresses = async () => {
      console.log("m here");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/shipping-addresses/user_id_${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setaddresses(response.data.addresses);
        setSelectedAddressId(response.data.addresses[0].id);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };
    userAddresses();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchUserData = () => async (dispatch) => {
    // await dispatch(setUserProfile());
    await dispatch(userAddresses());
  };

  // Then use a single `useEffect`
  // useEffect(() => {
  //   dispatch(fetchUserData());
  // }, [dispatch]);

  useEffect(() => {
    console.log("order details api ");
    const fetchBuyNow = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}buyorders/${id}`
        );
        console.log("data buy", response.data);

        setIsProduct(response.data);
        setColor(response.data.color);
        setImage(response.data.image_url);
        setProductName(response.data.product_name);
        setSize(response.data.size);
        setPrice(response.data.price);
        setOldPrice(response.data.old_price)
        setVendorId(response.data.vendor_id);
        const productId = response.data.products;

        setProductId(productId);

        const product = products.filter((item) => item.id === productId);
        console.log("product", product);
        setProduct(product[0]);
        const ProductPrices = product[0].prices.filter(
          (item) => item.color_name === response.data.color
        );

        setProductPrice(ProductPrices[0]);
        const image = ProductPrices[0].images[0].image_path;
        setbuyImage(image);

        // setFormData(setOrderObj);
      } catch (error) {
        console.error("Error fetching buy now data:", error);
      }
    };
    fetchBuyNow();
  }, [selectedPayment]);

  const handleAddressSelect = (e) => {
    const addressId = e.target.value;
    setSelectedAddress(addressId);
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Function to handle placing the order
  const handlePlaceOrder = async () => {
    const orderItem = [
      {
        product_id: productid,
        size: size,
        color: color,
        sales_price: price,
        old_price: old_price,
        vendor_id: VendorId,
        total_price: price,
        quantity: 1,
      },
    ];

    const orderData = {
      total_items: 1, // Set as 1 since there’s only one item
      payment_type: payementType,
      payment_status: "PENDING",
      total_amount: price,
      net_amount: price,
      shipping_charges: 0.0, // Static value, adjust as needed
      status: "placed",
      user_id: userId, // Replace with the dynamic user ID
      shipping_address_id: selectedAddressId, // Replace with the dynamic shipping address ID
      order_items: orderItem,

      // Send the single order item
    };
    try {
      console.log("order send", orderData);
      if (selectedAddressId && payementType) {
        const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}cart/orders_cart`,
          orderData
        );

        if (response.status === 200) {
          // If order creation is successful, update successOrder state
          setSuccessOrder(true);
        }

        // Proceed with delete API only if the order was successfully placed
        if (successOrder) {
          const deleteResponse = await axios.delete(
            `${process.env.REACT_APP_PUBLIC_API_BASE_URL}api/buyorders/${id}`
          );
        }

        // Navigate to the 'my orders' page
        navigate("/order_sucess_page");
      }
    } catch (error) {
      console.error("Error in placing order or deleting order:", error);
    }
  };

  const handleaddAddress = () => {
    navigate("/account/newaddress-form");
  };

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
      {/* Left Section - Address, Cart Items, Payment Options */}
      <div className="md:col-span-2 bg-white p-5 rounded-lg shadow-lg">
        {/* Cart Items */}
        {isProduct ? (
          <div className={`mb-6 shadow-lg`}>
            <h2 className="text-xl font-bold mb-2 cursor-pointer  pt-3 pb-3 pl-3 text-gray-500">
              Your Item
            </h2>

            <ul className="space-y-4 pl-5 pr-5 pb-2">
              <li className="flex justify-between items-center border p-2">
                <div className="flex">
                  <div>
                    <img
                      className="w-20 h-20 object-cover"
                      src={`https://ourmicrolife.com/ourmicrolife/storage/app/public/${Imagefeature}`}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="font-semibold text-lg">{productName}</p>
                    <p className="text-gray-600">Color: {color}</p>
                    <p className="text-gray-600">Configuration: {size}</p>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Price:{price}</p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Quantity:</p>
                    <p className="text-green-600 font-semibold">1</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* Payment Options */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "address" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500"
            onClick={() => toggleSection("address")}
          >
            Choose Delivery Address
            <span className="ml-2 text-gray-500">
              {activeSection === "address" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "address" && (
            <div className="space-y-4 pl-8 pr-5 ">
              {addresses.map((address, index) => (
                <div
                  key={address.id}
                  className="flex items-center space-x-3  border-b-2 pb-2"
                >
                  <input
                    type="radio"
                    id={`address-${address.id}`}
                    name="address"
                    value={address.id}
                    onChange={handleAddressSelect}
                    onClick={() => {
                      setSelectedAddressIndex(index);
                      setSelectedAddressId(address.id);
                    }}
                    checked={index === selectedAddressIndex}
                  />
                  <label
                    htmlFor={`address-${address.id}`}
                    className="ml-2  mr-5 w-full"
                  >
                    <div className="flex justify-between mb-3">
                      <div className="font-semibold text-lg text-gray-700">
                        {address.full_name}
                      </div>
                      <div className="text-sm text-gray-600">
                        {address.mobile_number}
                      </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-2">
                      {address.full_address} - {address.near_by_address},{" "}
                      {address.city}, {address.state}
                    </div>
                    <div className="text-sm text-gray-600">
                      Pin Code: {address.pin_code}
                    </div>
                  </label>
                </div>
              ))}
              <div className="pb-2 " onClick={() => handleaddAddress()}>
                <div className="bg-purple-500 flex justify-center items-center text-white lg:pt-3 lg:pb-3 sm:pb-2 sm:pt-2">
                  Add Address
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Payment Options */}
        <div
          className={`mb-6 shadow-lg ${
            activeSection === "payment" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500"
            onClick={() => toggleSection("payment")}
          >
            Payment Options
            <span className="ml-2 text-gray-500">
              {activeSection === "payment" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "payment" && (
            <div className="space-y-4 pl-8 pb-3">
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="upi"
                  name="payment"
                  value="upi"
                  onChange={() => {
                    handlePaymentSelect("upi");
                    setPayementType("online_mode");
                  }}
                  checked={payementType === "online_mode"}
                />
                <label htmlFor="upi" className="ml-2">
                  Online Mode
                </label>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="cod"
                  name="payment"
                  value="cod"
                  onChange={() => {
                    handlePaymentSelect("COD");
                    setPayementType("cod");
                  }}
                  checked={payementType === "cod"}
                />
                <label htmlFor="cod" className="ml-2">
                  Cash on Delivery (COD)
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Right Section - Total Summary */}
      <div className="bg-white sticky top-20 p-6 rounded-lg shadow-lg sticky top-0">
        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>Total Items</p>
            {/* <p>{totalItems}</p> */}
            <p>1</p>
          </div>
          <div className="flex justify-between">
            <p>Total Price</p>
            <p> {price}</p>

            {/* <p className="font-semibold text-blue-500">₹{product.sale_price}</p> */}
          </div>
        </div>
        <hr className="my-4" />
        <button
          className="w-full py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300"
          onClick={() => handlePlaceOrder()}
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
        {successOrder && (
          <div className="mt-4 text-green-500 font-bold">
            Your order has been placed successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyNowOrderSummary;

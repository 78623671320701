import React from "react";
import Slider from "react-slick";
import image from "../images/ayush-mantralaya.webp";
import image1 from "../images/azadi-ka-amrit-mahotsav-hindi-logo-1BE5E3568C-seeklogo.com.png";
import image2 from "../images/mygov.webp";
import image3 from "../images/GMP.webp";
import image4 from "../images/swachh-bharat-abhiyan.webp";
import image5 from "../images/who.webp";

//swachh-bharat-abhiyan.webp
const BrandCarousel = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const images = [image, image1, image2, image3, image4, image5];

  return (
    <div className="bg-blue-50 p-8">
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div key={index} className="p-2">
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-48 bg-white rounded-md shadow-sm p-2"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandCarousel;

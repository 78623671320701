import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import image from "../../images/Website.png";
import { FaTag } from "react-icons/fa";
import { CiDeliveryTruck } from "react-icons/ci";

const MyOrders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterType, setFilterPaymetType] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [orders, setOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [productData, setProduct] = useState([]);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  const fetchOrder = async () => {
    if (!token || !userId) {
      setError("You need to log in to view your orders.");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}orders/${userId}`
      );
      const orderList = response.data;
      console.log(response.data);

      const productResponse = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}get`
      );
      const products = productResponse.data;
      console.log(products);
      setProduct(products);

      const orderData = orderList.data.map((order) =>
        order.order_items.map((item) => {
          const matchedProduct = products.find(
            (product) => product.id === item.product_id
          );
          let imagePath = null;

          if (matchedProduct && matchedProduct.prices) {
            const matchedPrice = matchedProduct.prices.find(
              (price) => price.color_name === item.color
            );

            if (
              matchedPrice &&
              matchedPrice.images &&
              matchedPrice.images.length > 0
            ) {
              imagePath = matchedPrice.images[0].image_path;
            }
          }

          return {
            ...item,
            status: order.status,
            product_name: matchedProduct?.name || "Unknown Product",
            created_at: order.created_at,
            image_path: imagePath,
            shipping_address_id: order.shipping_address_id,
            payment_status: order.payment_status,
            payment_type: order.payment_type,
          };
        })
      );
      console.log("order data", orderData);
      const flattenedOrderData = orderData.flat();
      setOrderDetail(flattenedOrderData.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch orders. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const filteredOrders = orderDetail.filter((order) => {
    const matchesSearch = order.product_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus = filterStatus
      ? order.status.toLowerCase().includes(filterStatus)
      : true;
    const matchesYear = filterYear
      ? order.created_at.toString().includes(filterYear)
      : true;
    const matchPaymentType = filterType
      ? order.payment_type.toLowerCase().includes(filterType)
      : true;
    return matchesSearch && matchesStatus && matchesYear && matchPaymentType;
  });

  const data = { orderDetail, productData };
  const handleOrdersDetails = (id) => {
    navigate(`/orders/${id}`, { state: data });
  };

  const handleFilter = () => {
    setSearchQuery("");
    setFilterStatus("");
    setFilterYear("");
    setFilterPaymetType(""); // Reset payment type filter too
  };

  const SkeletonLoader = () => (
    <div className="flex justify-between bg-white p-4 mb-4 rounded shadow animate-pulse">
      <div className="flex">
        <div className="w-20 h-20 bg-gray-200 rounded mr-4"></div>
        <div className="flex flex-col space-y-2">
          <div className="h-4 bg-gray-200 w-32"></div>
          <div className="h-4 bg-gray-200 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-end space-y-2">
        <div className="h-4 bg-gray-200 w-16"></div>
        <div className="h-4 bg-gray-200 w-32"></div>
      </div>
    </div>
  );
  const calculateDiscount = (salePrice, oldPrice) => {
    if (salePrice && oldPrice && oldPrice > salePrice) {
      return Math.round(((oldPrice - salePrice) / oldPrice) * 100);
    }
    return 0;
  };

  return (
    <div className=" bg-gray-100 lg:ml-5  sm:ml-3">
      {error ? (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-auto p-6 text-center mt-10 mb-10">
          <div className="flex justify-center mb-4">
            <img
              src={image} // Replace with the correct image URL
              alt="Error Icon"
              className="h-28 w-38"
            />
          </div>
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            Oops! Something went wrong.
          </h2>
          <p className="text-gray-600 text-sm mb-4">{error}</p>
          <button
            onClick={() => navigate("/login-user")} // Redirect to login
            className="bg-orange-500 text-white px-6 py-2 rounded-full hover:bg-orange-600 transition"
          >
            Login
          </button>
        </div>
      ) : (
        <div className="flex">
          <div className="lg:pl-5 bg-white p-4 rounded shadow-md sticky top-20 h-screen  lg:w-[250px]">
            <h2 className="text-lg font-semibold mb-4">Filters</h2>
            {/* <input
          type="text"
          placeholder="Search orders..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-blue-300 rounded mb-4"
        /> */}
            {/* <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="w-full p-2 border border-blue-300 rounded mb-4"
        >
          <option value="">All Status</option>
          <option value="pending">Pending</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select> */}
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Order Status</h3>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterStatus"
                  value=""
                  checked={filterStatus === ""}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mr-2"
                />
                All Status
              </label>
              {/* <label className="block mb-2">
    <input
      type="radio"
      name="filterStatus"
      value="pending"
      checked={filterStatus === "pending"}
      onChange={(e) => setFilterStatus(e.target.value)}
      className="mr-2"
    />
    Pending
  </label> */}
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterStatus"
                  value="completed"
                  checked={filterStatus === "completed"}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mr-2"
                />
                Completed
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterStatus"
                  value="placed"
                  checked={filterStatus === "placed"}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mr-2"
                />
                Placed
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterStatus"
                  value="cancelled"
                  checked={filterStatus === "cancelled"}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mr-2"
                />
                Cancelled
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterStatus"
                  value="returned"
                  checked={filterStatus === "returned"}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mr-2"
                />
                Returned
              </label>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold mb-2">Select Year</h3>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="2021"
                  checked={filterYear === "2021"}
                  onChange={(e) => setFilterYear(e.target.value)}
                  className="mr-2"
                />
                2021
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="2022"
                  checked={filterYear === "2022"}
                  onChange={(e) => setFilterYear(e.target.value)}
                  className="mr-2"
                />
                2022
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="2023"
                  checked={filterYear === "2023"}
                  onChange={(e) => setFilterYear(e.target.value)}
                  className="mr-2"
                />
                2023
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="2024"
                  checked={filterYear === "2024"}
                  onChange={(e) => setFilterYear(e.target.value)}
                  className="mr-2"
                />
                2024
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="2025"
                  checked={filterYear === "2025"}
                  onChange={(e) => setFilterYear(e.target.value)}
                  className="mr-2"
                />
                2025
              </label>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Select Payment Type</h3>

              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="COD"
                  checked={filterType === "COD"}
                  onChange={(e) => setFilterPaymetType(e.target.value)}
                  className="mr-2"
                />
                Cash On Delivery
              </label>
              <label className="block mb-2">
                <input
                  type="radio"
                  name="filterYear"
                  value="Prepaid"
                  checked={filterType === "Prepaid"}
                  onChange={(e) => setFilterPaymetType(e.target.value)}
                  className="mr-2"
                />
                PrePaid
              </label>
            </div>

            <button
              onClick={handleFilter}
              className="w-full bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            >
              Clear Filters
            </button>
          </div>
          <div className="flex-grow sticky top-20 p-3 rounded-md pr-5 ">
            <input
              type="text"
              placeholder="Search orders..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 border border-blue-300 rounded mb-4"
            />
            {loading ? (
              <div>
                {/* Skeleton Loader */}
                {[...Array(5)].map((_, index) => (
                  <SkeletonLoader key={index} />
                ))}
              </div>
            ) : orderDetail.length > 0 ? (
              filteredOrders.map((order) => (
                <div
                  key={order.id}
                  onClick={() => handleOrdersDetails(order.id)}
                  className="flex justify-between bg-white p-2 mb-2 rounded shadow"
                >
                  <div className="flex w-1/2 ">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_PRODUCT_IMAGES_BASE_URL}/storage/app/public/${order.image_path}`}
                      alt={order.product_name}
                      className="w-20 h-20 rounded-sm object-cover mr-4"
                    />
                    <div>
                      <div
                        className="bg-red-100 px-1 w-[70px] rounded-sm"
                        style={{ fontSize: "10px" }}
                      >
                        <p ClassName="">OrderID:#{order.order_id}</p>
                      </div>
                      <p className="font-semibold ">{order.product_name}</p>
                      {order.color && (
                        <div className="flex">
                          <p className="text-sm font-semibold text-black-500">
                            Color:
                          </p>
                          <p className="text-sm font-semibold text-gray-500 pl-1">
                            {order.color}
                          </p>
                        </div>
                      )}
                      {order.size && (
                        <div className="flex">
                          <p className="text-sm font-semibold text-black-500">
                            Size:
                          </p>
                          <p className="text-sm font-semibold text-gray-500 pl-1">
                            {order.size}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    clasName="flex    border-2 "
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="flex flex-col  gap-1">
                      <p className="text-sm font-semibold text-gray-400 line-through decoration-red-500">
                        ₹{order.old_price}
                      </p>
                      <p className="text-lg font-semibold text-green-400">
                        ₹{order.sales_price}
                      </p>
                      <div className="bg-[#b22222] text-white flex items-center px-2 py-1 rounded-md"  style={{ fontSize: "10px" }}>
                        <FaTag className="mr-2 text-sm" />
                        <p className="text-sm font-medium">
                          {calculateDiscount(
                            order.sales_price,
                            order.old_price
                          )}
                          % OFF
                        </p>
                      </div>
                    </div>

                    <div className=" flex justify-center items-center lg:pr-10"   >
                      {order.status && (
                        <p className=" font-semibold text-gray-500 ">
                          🔴{order.status}
                        </p>
                      )}
                      <p className="text-sm text-gray-400">
                        {order.statusDescription}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center bg-white p-6 rounded shadow-md mt-10">
                <img
                  src={image} // Replace with the correct path to your image
                  alt="No Orders"
                  className="w-32 h-32 mb-4"
                />
                <h2 className="text-lg font-semibold text-gray-800 mb-2">
                  No Orders Found
                </h2>
                <p className="text-green-400 text-sm">
                  Looks like you haven't placed any orders yet. Start shopping
                  now!
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyOrders;

import React, { useState } from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const AddReview = () => {
  const [reviews, setReviews] = useState([
    { id: 1, name: "John Doe", rating: 4, comment: "Great product!" },
    {
      id: 2,
      name: "Jane Smith",
      rating: 5,
      comment: "Excellent quality and fast shipping.",
    },
  ]);

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const newReview = {
      id: reviews.length + 1,
      name,
      rating,
      comment,
    };
    setReviews([...reviews, newReview]);
    setName("");
    setEmail("");
    setRating(0);
    setComment("");
  };

  return (
    <div className="flex flex-col md:flex-row mt-10 mr-10 ml-10">
      {/* Reviews List */}
      <div className="w-full md:w-2/3 p-4">
        <h3 className="text-2xl font-bold mb-4">Reviews</h3>
        {reviews.length > 0 ? (
          reviews.map((review) => (
            <div key={review.id} className="mb-4 border-b pb-4">
              <h4 className="text-lg font-semibold">{review.name}</h4>
              <div className="flex">
                {[...Array(5)].map((_, index) => (
                  <FaStar
                    key={index}
                    className={`text-yellow-400 ${
                      index < review.rating ? "" : "text-gray-300"
                    }`}
                  />
                ))}
              </div>
              <p className="text-gray-700 mt-2">{review.comment}</p>
            </div>
          ))
        ) : (
          <p>No reviews yet.</p>
        )}
      </div>

      {/* Add Review Form */}
      <div className="w-full md:w-1/3 border rounded-xl p-4 mt-4 md:mt-0 sticky top-4">
        <h3 className="text-2xl font-bold mb-4">Add a Review</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-lg font-semibold mb-2">
              Your Rating
            </label>
            <div className="flex">
              {[...Array(5)].map((_, index) => {
                const currentRating = index + 1;
                return (
                  <FaStar
                    key={index}
                    className={`cursor-pointer ${
                      currentRating <= (hover || rating)
                        ? "text-yellow-400"
                        : "text-gray-300"
                    }`}
                    onClick={() => setRating(currentRating)}
                    onMouseEnter={() => setHover(currentRating)}
                    onMouseLeave={() => setHover(rating)}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <label className="block text-lg font-semibold mb-2">
              Your Name
            </label>
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-lg font-semibold mb-2">
              Your Email
            </label>
            <input
              type="email"
              className="w-full p-2 border rounded"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-lg font-semibold mb-2">
              Your Review
            </label>
            <textarea
              className="w-full p-2 border rounded"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="bg-purple-500 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition-colors"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReview;

import React, { useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from "../images/profiledummy.png";

const TeamCarousel = () => {
  const navigate = useNavigate();
  const sliderRef = useRef(null); // Create a reference to access the Slider

  const items = [
    {
      image: image,
      text: "This is the first image",
      link: "/details/1",
    },
    {
      image: image,
      text: "This is the second image",
      link: "/details/2",
    },
    {
      image: image,
      text: "This is the third image",
      link: "/details/3",
    },
    {
      image: image,
      text: "This is the fourth image",
      link: "/details/4",
    },
    {
      image: image,
      text: "This is the fifth image",
      link: "/details/5",
    },
  ];

  const settings = {
    dots: false, // Show navigation dots
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 5, // Show 4 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Handle custom Prev and Next button actions
  const handlePrev = () => {
    sliderRef.current.slickPrev(); // Move to the previous slide
  };

  const handleNext = () => {
    sliderRef.current.slickNext(); // Move to the next slide
  };

  return (
    <div className="bg-purple-50 lg:px-5 relative">
      <div className="">
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, index) => (
            <div key={index} className="p-4">
              {/* Image */}
              <img
                src={item.image}
                alt={item.text}
                onClick={() => navigate(item.link)}
                className="rounded-lg bg-white shadow-md w-full lg:h-60 sm:h-40 object-cover cursor-pointer hover:opacity-90"
              />
              {/* Text */}
              <p className="mt-2 text-center font-semibold text-gray-800">
                {item.text}
              </p>
              <p className="text-center font-semibold text-gray-800">level-2</p>
            </div>
          ))}
        </Slider>
      </div>

      {/* Custom Prev and Next buttons */}
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-10 transform -translate-y-1/2 bg-gray-300 text-purple-500 p-2 rounded-full hover:bg-purple-500 hover:text-white"
      >
        &#10094;
      </button>
      <button
        onClick={handleNext}
        className="absolute top-1/2 right-10 transform -translate-y-1/2 bg-gray-300 text-purple-500 p-2 rounded-full hover:bg-purple-500 hover:text-white"
      >
        &#10095;
      </button>
    </div>
  );
};

export default TeamCarousel;

import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import image from "../images/loginImage.png";

const Login = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);

  const fetchLogin = async (mobileNumber) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/login`,
        { mobile_number: mobileNumber },
        { headers: { "Content-Type": "application/json" } }
      );
      const data = response.data;
      console.log(response.data)
      localStorage.setItem("userId", data.user.id);
      localStorage.setItem("token", data.api_token);
      return data.api_token;
    } catch (error) {
      console.error("Error in login: ", error);
      alert(
        "Error in sending OTP. Try SignUp first",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSendOtp = async () => {
    if (mobileNumber && mobileNumber.length === 10) {
      const token = await fetchLogin(mobileNumber);
      setOtpSent(true);
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      alert("Please enter a valid 10-digit mobile number");
    }
  };

  const handleResendOtp = async () => {
    if (mobileNumber && mobileNumber.length === 10) {
      setResendDisabled(true);
      try {
        await fetchLogin(mobileNumber);
        alert("OTP resent successfully!");
      } catch (error) {
        console.error("Error in resending OTP: ", error);
        alert("Error in resending OTP. Please try again later.");
      }
      setTimeout(() => setResendDisabled(false), 60000);
    } else {
      alert("Please enter a valid 10-digit mobile number");
    }
  };

  const handleLogin = async () => {
    if (otp) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/verify-otp-auth`,
          { mobile_number: mobileNumber, otp },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data)
        alert("OTP Verified! Logging in...");
        navigate("/");
      } catch (error) {
        console.error("Error in OTP verification: ", error);
        alert(
          "Error in OTP verification",
          error.response ? error.response.data : error.message
        );
      }
    } else {
      alert("Please enter the OTP sent to your mobile");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-50">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl w-full bg-white p-6 md:p-8 shadow-lg rounded-lg">
        {/* Image Section */}
        <div className="hidden md:block">
          <img
            src={image}
            alt="Login Illustration"
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>

        {/* Login Form Section */}
        <div className="flex flex-col justify-center">
          <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
          {/* Mobile Number Input */}
          <div className="mb-4">
            <label htmlFor="mobile_number" className="block text-gray-600 mb-2">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile_number"
              value={mobileNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numbers and ensure the length is not more than 10
                if (/^\d*$/.test(value) && value.length <= 10) {
                  setMobileNumber(value);
                }
              }}
              placeholder="Enter your mobile number"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Send OTP Button */}
          {!otpSent && (
            <button
              onClick={handleSendOtp}
              className="w-full bg-purple-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Send OTP
            </button>
          )}

          {/* OTP Input & Login Section */}
          {otpSent && (
            <>
              <div className="mb-4 mt-4">
                <label htmlFor="otp" className="block text-gray-600 mb-2">
                  Enter OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <button
                onClick={handleLogin}
                className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-orange-600 transition"
              >
                Login
              </button>

              <button
                onClick={handleResendOtp}
                disabled={resendDisabled}
                className={`w-full mt-3 py-2 rounded-lg transition ${
                  resendDisabled
                    ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                    : "bg-purple-500 text-white hover:bg-blue-500"
                }`}
              >
                Resend OTP
              </button>
            </>
          )}

          {/* Signup Redirect */}
          <div className="mt-4 text-center">
            <p className="text-gray-600">
              Don't have an account?{" "}
              <Link to="/signup-user" className="text-blue-500 hover:underline">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

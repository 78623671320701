import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import image from "../images/image1.jpg";
import Categories from "./Categories";
import { MdIosShare } from "react-icons/md";

const StoreDetail = () => {
  const { id } = useParams();
  const [shop, setShop] = useState(null);

  const fetchStore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}user/shops/shop_id_${id}`
      );
      setShop(response.data.shop);
    } catch (error) {
      console.error("Error in fetching stores", error);
    }
  };

  useEffect(() => {
    fetchStore();
  }, [id]);

  if (!shop) {
    return <p className="text-center text-xl font-medium mt-10">Loading...</p>;
  }

  const handleShare = (shop) => {
    const shareData = {
      title: shop.shop_name,
      text: `Check out this shop: ${shop.shop_name} (${shop.shop_category})`,
      url: window.location.href, // current page URL
    };

    // Use Web Share API if available
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Shared successfully"))
        .catch((err) => console.error("Error sharing:", err));
    } else {
      // Fallback links for manual sharing
      const emailSubject = `Check out this shop: ${shop.shop_name}`;
      const emailBody = `Here's the shop you might like:\n\nShop Name: ${shop.shop_name}\nCategory: ${shop.shop_category}\nAddress: ${shop.shop_address}\nLink: ${window.location.href}`;
      const whatsappMessage = `Check out this shop: ${shop.shop_name}\nCategory: ${shop.shop_category}\nAddress: ${shop.shop_address}\nLink: ${window.location.href}`;
      const shareLinks = `
        Email: mailto:?subject=${encodeURIComponent(
          `Check out ${shop.shop_name}`
        )}&body=${encodeURIComponent(
        `Here's the link: ${window.location.href}`
      )}
        WhatsApp: https://wa.me/?text=${encodeURIComponent(
          `Check out this shop: ${shop.shop_name} - ${window.location.href}`
        )}
      `;
      alert(`Share via:\n${shareLinks}`);
    }
  };

  return (
    <div className="">
      {/* Image Section */}
      <div className="relative w-full h-[300px] mb-4 ">
        {/* Main Image */}
        <img
          src={image}
          alt="Vendor"
          className=" border-2 border-gray-300 shadow-lg object-cover w-full h-full"
        />

        {/* Logo */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <img
            src={image}
            alt="Logo"
            className="w-24 h-24 rounded-full border-2 border-white shadow-lg"
          />
        </div>
      </div>

      {/* Store Info Section */}
      <div className="flex flex-col items-center lg:items-start space-y-6 lg:space-y-8 px-2">
        {/* Shop Name and Category */}

        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          {/* Shop Name and Shop Category */}
          <div className="">
            {/* Shop Name and Share Icon */}
            <div className="flex items-center ">
              <p className="text-2xl font-bold text-gray-900">
                {shop.shop_name}
              </p>
              <button
                className="text-black-800 text-[20px] hover:text-blue-500"
                aria-label="Share"
                onClick={() => handleShare(shop)}
              >
                <MdIosShare className="font-bold ml-2" />
              </button>
            </div>

            <p className="font-semibold text-black-700 text-[15px]">
              Shop Category: {shop.shop_category}
            </p>
          </div>
        </div>

        {/* Ratings and Description */}
        <div className="text-lg text-gray-500">
          <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate, lorem eget vehicula fermentum, purus nisi cursus eros, ut
            gravida ligula mauris eu odio. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nullam vulputate, lorem eget vehicula
            fermentum, purus nisi cursus eros, ut gravida ligula mauris eu odio.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate, lorem eget vehicula fermentum, purus nisi cursus eros, ut
            gravida ligula mauris eu odio. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nullam vulputate, lorem eget vehicula
            fermentum, purus nisi cursus eros, ut gravida ligula mauris eu odio.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate, lorem eget vehicula fermentum, purus nisi cursus eros, ut
            gravida ligula mauris eu odio. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nullam vulputate, lorem eget vehicula
            fermentum, purus nisi cursus eros, ut gravida ligula mauris eu odio.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            vulputate, lorem eget vehicula fermentum, purus nisi cursus eros, ut
            gravida ligula mauris eu odio.
          </p>
        </div>

        {/* Contact Section */}
        {/* <div className="p-4 border-t border-b border-gray-200 w-full">
        <address className="not-italic text-gray-700">
          <p className="font-bold">Contact us at:</p>
          <p className="mt-2">
            <a
              href="mailto:contact@example.com"
              className="text-blue-500 hover:underline"
            >
              contact@example.com
            </a>
            <br />
            Phone: (123) 456-7890
            <br />
            123 Example Street
            <br />
            {shop.shop_address}
          </p>
        </address>
      </div> */}
      </div>

      {/* Categories Section */}
      <div className="mt-10">
        <Categories />
      </div>
    </div>
  );
};

export default StoreDetail;

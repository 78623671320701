import React from "react";
import { Link } from "react-router-dom";
import Googlebtn from "../images/google-playstore.png";
import Applebtn from "../images/apple-downld-btn.jpeg";
import { FaWhatsapp, FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="px-6 py-10 bg-purple-500 text-white">
      <div className="grid sm:grid-cols-2 lg:flex lg:justify-between lg:gap-8 md:gap-5">
        <div className="lg:w-1/2 mb-6 lg:mb-0">
          <h1 className="text-[30px] md:text-[40px]">OurMicroLife</h1>
          <h2 className="text-[18px] md:text-[20px] font-medium pt-5">
          OurMicroLife
          </h2>
          <p className="mt-3 text-sm">
          OurMicroLife is an innovative e-commerce platform connecting buyers and trusted vendors, offering a seamless shopping experience with diverse products, competitive prices, and reliable services tailored to your needs.
          </p>
          <div className="flex pt-3 space-x-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              className="text-white"
            >
              <FaFacebook size={30} />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              className="text-white"
            >
              <FaTwitter size={30} />
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
              className="text-white"
            >
              <FaYoutube size={30} />
            </a>
          </div>
        </div>

        <div className="lg:w-1/2 mb-6 lg:mb-0">
          <h2 className="text-[18px] md:text-[20px] font-medium pt-5 pb-3">
            Most Popular Categories
          </h2>
          <ul className="space-y-2 text-sm md:text-base">
            <li>Home care</li>
            <li>Personal care</li>
            <li>Beauty</li>
            <li>Health & Wellness</li>
            <li>Kitchen essentials</li>
            <li>Electronics</li>
            <li>Furniture</li>
          </ul>
        </div>

        <div className="lg:w-1/2 mb-6 lg:mb-0">
          <h2 className="text-[18px] md:text-[20px] font-medium pt-5 pb-3">
            Customer Services
          </h2>
          <ul className="space-y-2 text-sm md:text-base">
            <Link to="/about_us">
              <li>About Us</li>
            </Link>
           <Link to="/terms_conditions"><li>Terms and Conditions</li></Link> 
         
            <Link to="/privacy_policy">
              <li>Privacy Policy</li>
            </Link>
            <Link to="/disclaimer">    
              <li>Disclaimer</li>
            </Link>
            <Link to="/refund_cancellation">
              <li>Refund & Cancellation</li>
            </Link>
            <Link to="/shipping_returns"><li>Shipping & Delivery</li></Link>
            <Link to="/contact_us">
              <li>Contact Support</li>
            </Link>
          </ul>
        </div>

        <div className="lg:w-1/2">
          <Link to="/contact_us">
            <h2 className="text-[18px] md:text-[20px] font-medium pt-5">
              Contact Us
            </h2>
          </Link>
          <div className="flex items-center pt-4 space-x-2">
            <FaWhatsapp className="text-white" />
            <div>
              <h3 className="text-sm font-medium">Whatsapp</h3>
              <p className="text-sm">+91 0000000000</p>
            </div>
          </div>
          <div className="flex items-center pt-4 space-x-2">
            <IoCallOutline className="text-white" />
            <div>
              <h3 className="text-sm font-medium">Call Us</h3>
              <p className="text-sm">+91 0000000000</p>
            </div>
          </div>

          <div className="pt-4">
            <h3 className="text-[18px] md:text-[20px] font-medium">
              Download App
            </h3>
            <div className="flex space-x-4 mt-2">
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={Googlebtn}
                  alt="Download on Google Play"
                  className="w-[120px] md:w-[150px] h-[45px] object-contain"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/idXXXXXXXXX"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  src={Applebtn}
                  alt="Download on App Store"
                  className="w-[120px] md:w-[150px] rounded-lg object-contain"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

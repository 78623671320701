import React, { useEffect, useState } from "react";
import axios from "axios";

import { FaWallet } from "react-icons/fa"; // Wallet icon
import { GiTwoCoins } from "react-icons/gi"; // Yellow coin icon

const Wallet = () => {
  const userId = localStorage.getItem("userId");
  const [coin, setCoins] = useState([]);
  const transactions = [
    {
      description: "Expire Coins",
      date: "Debited on 01 Dec 2024",
      coins: -58,
    },
    {
      description: "Allen Solly Full Sleeve Solid Men Sweatshirt",
      date: "To be credited by 10 Dec 2024",
      coins: "⏳ 20",
    },
    {
      description:
        "Harpic Disinfectant Toilet Cleaner Liquid, Original - 5 L (Pack of ...)",
      date: "Credited on 26 Nov 2024 | Valid till 31 May 2025",
      coins: "+56",
    },
    {
      description: "PETER ENGLAND Full Sleeve Striped Men Sweatshirt",
      date: "Credited on 24 Nov 2024",
      coins: "+10",
    },
  ];

  const fetchCoins = async () => {
    console.log("hey");
    try {
      const response = await axios.get(
        `https://ourmicrolifebackend.onrender.com/api/coins/19`
      );
      console.log("response", response.data);
      setCoins(response.data.Data.coinsValue);
    } catch (error) {
      console.error("error in fetching error ", error);
    }
  };

  useEffect(() => {
    fetchCoins();
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen p-6 flex justify-center items-center">
      <div className="max-w-4xl w-full bg-white shadow-md rounded-lg p-6">
        {/* Wallet Header */}
        <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm mb-6">
          {/* Left Section with Wallet Icon and Title */}
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-gray-800">Wallet Coins</h1>
          </div>

          {/* Right Section with Coin Count */}
          <div className="flex items-center space-x-2">
            <span className="text-lg font-medium text-gray-700">{coin}</span>
            <GiTwoCoins className="text-yellow-400 text-3xl" />
          </div>
        </div>

        {/* Transaction History Header */}
        <h1 className="text-xl font-semibold mb-4">Transaction History</h1>
        <p className="bg-gray-100 text-gray-600 text-sm p-3 rounded-md mb-6">
          Coins will be credited to your wallet after 3–4 business days from the
          delivery of the product. This process ensures the verification of the
          delivery and confirmation of the order's completion.
        </p>

        {/* Recent Coin Activity */}
        <h2 className="text-md font-medium mb-4">Recent Coin Activity</h2>

        {/* Transactions List */}
        <div className="space-y-4">
          {transactions.map((transaction, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-b pb-4"
            >
              <div>
                <p className="text-gray-800 font-medium">
                  {transaction.description}
                </p>
                <p className="text-sm text-gray-500">{transaction.date}</p>
              </div>
              <p
                className={`text-lg font-semibold ${
                  transaction.coins > 0
                    ? "text-green-500"
                    : transaction.coins < 0
                    ? "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {transaction.coins}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
